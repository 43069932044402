import * as _ from 'lodash'
const defaultStudentData = {
  firstName: null,
  lastName: null,
  DOB: null,
  startingLesson: {},
  password: null
}
const defaultSubscriptionData = {
  programId: null,
  selectedCourseSetsWithCourses: [],
  subscriberParent: {
    firstName: null,
    lastName: null,
    billingPreferences: { address: null },
    username: null,
    email: null,
    office: null
  },
  student: _.cloneDeep(defaultStudentData)
}
export default {
  state: {
    newSubscription: _.cloneDeep(defaultSubscriptionData),
    studyPlan: []
    // setCurrentOnGet: false
  },

  getters: {
    getNewSubscription (state) {
      return state.newSubscription
    },
    getSubscriberParent (state) {
      return state.newSubscription.subscriberParent
    },
    getStudyPlan (state) {
      return state.studyPlan
    },
    getStudyPlanItem: state => (id) => {
      return state.studyPlan[id]
    }
  },
  mutations: {
    setSelectedProgramId (state, newValue) {
      state.newSubscription.programId = newValue
    },
    setSelectedCourses (state, newValue) {
      state.newSubscription.selectedCourseSetsWithCourses = newValue
    },

    setSubscriberParent (state, newValue) {
      state.newSubscription.subscriberParent = newValue
    },
    setStudent (state, newValue) {
      state.newSubscription.student = newValue
    },
    // addSampleStudents (state, newValue) {
    //   state.newSubscription.students.push(defaultStudentData)
    // },
    // removeStudentByIndex (state, index) {
    //   state.newSubscription.students.splice(index)
    // },
    resetSubscriptionData (state) {
      state.newSubscription = _.cloneDeep(defaultSubscriptionData)
    },
    setStudyPlan (state, newValue) {
      state.studyPlan = newValue
    },
    setStudyPlanItem (state, obj) {
      state.studyPlan[obj.index] = obj.newValue
    },
    setFirstName (state, value) {
      state.newSubscription.subscriberParent.firstName = value
    },
    setLastName (state, value) {
      state.newSubscription.subscriberParent.lastName = value
    },
    setAddress (state, value) {
      state.newSubscription.subscriberParent.billingPreferences.address = value
    },
    setUserName (state, value) {
      state.newSubscription.subscriberParent.username = value
    },
    setEmail (state, value) {
      state.newSubscription.subscriberParent.email = value
    },
    setOffice (state, value) {
      state.newSubscription.subscriberParent.office = value
    },
    setStudentFirstName (state, value) {
      state.newSubscription.student.firstName = value
    },
    setStudentLastName (state, value) {
      state.newSubscription.student.lastName = value
    },
    setStudentDOB (state, value) {
      state.newSubscription.student.DOB = value
    },
    setStudentPassword (state, value) {
      state.newSubscription.student.password = value
    },
    setStudentStartingLesson (state, value) {
      state.newSubscription.student.startingLesson = value
    }
  }
}
