import { Context } from '@nuxt/types'
import * as cookie from 'cookie'
export default function (context: Context) {
  const { redirect, route, req, store } = context
  try {
    if (req) {
      const parsedCookie = cookie.parse(req.headers.cookie || '')
      const feathersToken = parsedCookie['feathers-jwt']
      if (feathersToken) {
        return redirect('/')
      } else if (route.path !== '/login') {
        return redirect('/login')
      }
    } else if (!store.state.auth.accessToken) {
      if (route.path !== '/login') {
        return redirect('/login')
      }
    }
  } catch (error) {
  }
}
