const middleware = {}

middleware['auth'] = require('../../client/middleware/auth.ts')
middleware['auth'] = middleware['auth'].default || middleware['auth']

middleware['impersonation'] = require('../../client/middleware/impersonation.js')
middleware['impersonation'] = middleware['impersonation'].default || middleware['impersonation']

middleware['permissions'] = require('../../client/middleware/permissions.js')
middleware['permissions'] = middleware['permissions'].default || middleware['permissions']

middleware['switchSpa'] = require('../../client/middleware/switchSpa.js')
middleware['switchSpa'] = middleware['switchSpa'].default || middleware['switchSpa']

export default middleware
