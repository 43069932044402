<template>
  <svg
    id="ico_off_change_viewprofile"
    xmlns="http://www.w3.org/2000/svg"
    :width="width"
    :height="height"
    viewBox="0 0 32 27"
    class="drp-icon"
  >
    <g id="icon_student" data-name="icon student">
      <path
        id="Path"
        class="cls-1"
        d="M6.807,0V1.98c2.068.836,3.3,1.882,3.3,2.8C10.109,6.409,6.329,8.475,0,9.137V11c5.559-.577,12-2.544,12-6.216C12,2.353,9.205.846,6.807,0Z"
        transform="translate(20 14)"
      />
      <path
        id="Path-2"
        class="cls-1"
        data-name="Path"
        d="M0,4.773c0,3.621,6.584,5.593,12.222,6.182l.084.009v1.646a.144.144,0,0,0,.079.127l.468.246a.162.162,0,0,0,.155,0l3.92-2.349A.146.146,0,0,0,17,10.506v-.492a.145.145,0,0,0-.071-.123L13.009,7.541a.16.16,0,0,0-.156,0l-.468.246a.145.145,0,0,0-.079.128V9.1l-.1-.011C5.789,8.41,1.957,6.36,1.957,4.773c0-.921,1.275-1.964,3.415-2.8V0C2.891.844,0,2.348,0,4.773Z"
        transform="translate(0 14)"
      />
      <path
        id="Shape"
        class="cls-1"
        d="M14.5,18c-4.45,0-9.67-2.808-9.67-4.248V8.285L.383,6.556a.6.6,0,0,1,0-1.116l13.9-5.4a.606.606,0,0,1,.436,0l13.9,5.4a.6.6,0,0,1,0,1.116L24.17,8.285v5.467C24.17,15.192,18.95,18,14.5,18ZM6,9v4.9c.378.641,4.334,3.1,8.5,3.1a14.309,14.309,0,0,0,5.673-1.3A8.29,8.29,0,0,0,23,13.9V9l-8.282,3.182a.62.62,0,0,1-.437,0Zm8.5-8L2,6l12.5,5L27,6,14.5,1Z"
        transform="translate(2)"
      />
    </g>
  </svg>
</template>

<script>
export default {
  name: 'Clock',
  components: {},
  props: {
    height: {
      type: String,
      default: ''
    },
    width: {
      type: String,
      default: ''
    }
  },
  data () {
    return {}
  },
  computed: {}
}
</script>

<style lang="scss" scoped>
</style>
