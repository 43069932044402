import { Module } from 'vuex/types/index'
import _ from 'lodash'
import { User } from '../../src/models/users.model'
import { V20State } from '@/store'

interface FsLessonApiResumeData {}

export interface MiscState {
  currentUserId: string,
  availableUserData: User[],
  /** Data from fslesonapi */
  practiceResumeData: FsLessonApiResumeData,
  /** Data from fslesonapi */
  examResumeData: FsLessonApiResumeData
}

export default {
  state () {
    return {
      currentUserId: '',
      availableUserData: [],
      practiceResumeData: {},
      examResumeData: {}
    }
  },
  getters: {
    getCurrentUserId (state) {
      return state.currentUserId
    },
    getCurrentUserData (state) {
      return _.find(state.availableUserData, { _id: state.currentUserId })
    },
    getPracticeResumeData (state) {
      return state.practiceResumeData
    },
    getExamResumeData (state) {
      return state.examResumeData
    }
  },
  mutations: {
    setCurrentUserId (state, newValue: string) {
      state.currentUserId = newValue
      // console.log('TCL: setCurrentUserId -> state', state.availableUserData)
    },
    addUserData (state, userDataObj: User) {
      const index = _.findIndex(state.availableUserData, {
        _id: userDataObj._id
      })

      const userDataArr = state.availableUserData
      if (index >= 0) {
        userDataArr.splice(index, 1, userDataObj)
      } else {
        userDataArr.push(userDataObj)
      }
      state.availableUserData = userDataArr
      // console.log(
      //   'TCL: addUserData -> state.availableUserData 2',
      //   state.availableUserData
      // )
      state.currentUserId = userDataObj._id!
    },
    removeUserDataById (state, userId: string) {
      // console.log('TCL: removeUserDataById -> removeUserDataById')
      const index = _.findIndex(state.availableUserData, { _id: userId })
      if (index >= 0) {
        state.availableUserData = state.availableUserData.splice(index, 1)
      }
    },
    resetImpersonationData (state) {
      // console.log('TCL: resetImpersonationData -> resetImpersonationData')
      state.availableUserData = []
      state.currentUserId = ''
    },
    setPracticeResumeData (state, newValue: FsLessonApiResumeData) {
      state.practiceResumeData = newValue
    },
    setExamResumeData (state, newValue: FsLessonApiResumeData) {
      state.examResumeData = newValue
    }
  },
  actions: {
    logEvent ({ dispatch, rootGetters, rootState }, data) {
      const { $router } = this
      const mainPath = $router.currentRoute.path.split('/')[2]
      data.portal = mainPath ? mainPath.substring(0, mainPath.indexOf('portal')) : null
      if (!data.portal) { delete data.portal }
      const currentUserId = rootGetters['misc/getCurrentUserId'] // may be impersonate user
      const actualUser = rootState.auth.user._id// whatever user logged in
      if (actualUser !== currentUserId) {
        data.as = currentUserId
      }
      data.user = actualUser
      return dispatch('event-log/create', data, { root: true })
    }
  }
} as Module<MiscState, V20State>
