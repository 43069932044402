<template>
  <svg
    id="ico_log_out_on"
    data-name="ico_log out_on"
    xmlns="http://www.w3.org/2000/svg"
    :width="width"
    :height="height"
    viewBox="0 0 19 25"
    class="drp-icon"
  >
    <path
      id="ico_log_out_on-2"
      data-name="ico_log out_on"
      d="M19,25H7V22.991H17.122V1.955H7V0H19V25ZM4.932,18h0c-.024-.016-1.087-1.081-2.473-2.476C1.13,14.187,0,13.034,0,13.007s1.11-1.159,2.466-2.525L4.931,8l.692.7.692.7-1.3,1.31-1.3,1.31H14V14H3.713l1.3,1.31,1.3,1.31-.684.69c-.377.379-.684.682-.7.689Z"
      class="cls-1"
    />
  </svg>
</template>

<script>
export default {
  name: 'Logout',
  components: {},
  props: {
    height: {
      type: String,
      default: ''
    },
    width: {
      type: String,
      default: ''
    }
  },
  data () {
    return {}
  },
  computed: {}
}
</script>

<style lang="scss" scoped>
</style>
