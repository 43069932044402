import _ from 'lodash'
export default function (context) {
  const { store, route, error } = context
  const { auth } = store.state
  // console.log('TCL: store', store)
  // console.log('PERMSSIONS MIDDLEWARE')
  if (auth.user && !auth.publicPages.includes(route.name)) {
    const mainPath = route.path.split('/')[2]
    const permissions = [
      { path: 'adminportal', roles: ['admin', 'consultant', 'tutor'] },
      { path: 'parentportal', roles: ['parent', 'admin', 'consultant', 'tutor'] },
      { path: 'studentportal', roles: ['student', 'parent', 'admin', 'consultant'] }
    ]
    const row = _.find(permissions, { path: mainPath })
    if (row && !row.roles.includes(auth.user.type)) {
      error({
        message: 'Access Denied',
        statusCode: 401
      })
    }
    if (!store.state.users.switchToParentPortal && ['admin', 'parent'].includes(auth.user.type) && mainPath === 'parentportal') { // if admin in parent portal refreshes set the nav properly
      store.commit('users/setSwitchToParentPortal')
    }
  }
}
