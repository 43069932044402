const checkForHexRegExp = new RegExp('^[0-9a-fA-F]{24}$') // guid validate
export default async function (context) {
  const { store, redirect, params, error } = context
  const loggedInUser = store.state.auth.user
  if (params.userId) {
    const isvalidId = checkForHexRegExp.test(params.userId)
    if (!isvalidId) { return error({ statusCode: 404, message: 'This page could not be found' }) }
    let currentUserData = {}
    const currentUserIdInStore = store.getters['misc/getCurrentUserId']
    currentUserData = store.getters['misc/getCurrentUserData']
    if (!currentUserIdInStore || !currentUserData) {
      const userData = await store.dispatch('users/get', params.userId)
      if (loggedInUser._id !== params.userId) {
        let hasAccess = false
        if (
          (loggedInUser.type === 'admin' ||
            loggedInUser.type === 'consultant') &&
          (userData.type !== 'admin' || userData.type === 'consultant')
        ) {
          hasAccess = true
        }
        if (loggedInUser.type === 'parent') {
          if (loggedInUser.subscription === userData.subscription) {
            hasAccess = true
          }
        }
        if (hasAccess) {
          await store.commit('misc/setCurrentUserId', params.userId)
          await store.commit('misc/addUserData', userData)
        } else {
          return redirect('/')
        }
      } else {
        currentUserData = userData
        await store.commit('misc/setCurrentUserId', params.userId)
        await store.commit('misc/addUserData', userData)
      }
    }

    // TODO: check Store.state.auth.user.type is in record.meta.roles
    // if (!meta.roles.includes(currentUserData.type)) {
    //   redirect('/')
    // }
  }
}
