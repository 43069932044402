export default {
  state: {

    currentStudyPlanMeta: {
      lessonMode: 'MANUAL',
      currentStudyPlanId: null,
      currentCourseId: null

    }

  },
  getters: {
    getCurrentStudyPlanMeta (state) {
      return state.currentStudyPlanMeta
    }
  },
  mutations: {
    setCurrentStudyPlan (state, newStudyPlan) {
      state.currentStudyPlan = newStudyPlan
    },
    setCurrentStudyPlanId (state, newStudyPlanId) {
      state.currentStudyPlanId = newStudyPlanId
    }
  }
}
