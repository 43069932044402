<template>
  <div>
    <div class="full-height">
      <b-alert v-if="impersonating" show variant="warning" class="text-center" style="margin: 0">
        You are currently impersonating {{ user.firstName }} {{ user.lastName }}. Do you want to
        <a
          href="#"
          @click="stopImpersonate"
        >Stop impersonating</a>
      </b-alert>
      <b-alert
        v-if="user && user.type !== 'student' && inStudentPortal"
        style="text-align:center;margin-bottom:0"
        show
        variant="warning"
      >
        You are viewing the system as a student
        <b-link
          variant="outline-primary"
          size="sm"
          :to="'/' + user._id + '/parentportal'"
        >
          Go Back
        </b-link>
      </b-alert>
      <b-navbar
        v-else
        class="navbar-linkset"
        toggleable="lg"
        type="dark"
        variant="info"
      >
        <b-navbar-brand to="/">
          <b-img
            style="height:40px;"
            fluid
            src="../../client/assets/logo.png"
          />
        </b-navbar-brand>
        <b-navbar-toggle v-if="user" target="nav-collapse" />
        <b-collapse v-if="user" id="nav-collapse" is-nav class="">
          <b-navbar-nav class="ml-auto nav-r">
            <!-- portal menu items -->
            <!-- hidden -->
            <!-- <b-nav-item v-if="user" class="grp1 hidden-nav">
              <b-img
                class="prfile-nav-image"
                style="height:25px; width:25px"
                fluid
                :src="imgSrc"
              />
              <span
                class="user-name pl-1"
              >{{ user.firstName }} {{ user.lastName }}</span>
            </b-nav-item> -->
            <!-- e hidden -->

            <b-nav-item :class=" inStudentPortalHome? 'is nuxt-link-active' : ''" exact :to="homeLink">
              Home
              <div class="divider" />
            </b-nav-item>

            <b-nav-item
              v-if="user.type !== 'student' && switchToParentPortal"
              :to="'/' + user._id + '/parentportal/lessons'"
            >
              Lessons
              <div class="divider" />
            </b-nav-item>

            <b-nav-item
              v-if="user.type !== 'student' && switchToParentPortal"
              :to="'/' + user._id + '/parentportal/contactus'"
            >
              Contact Us
              <div class="divider" />
            </b-nav-item>
            <b-nav-item
              v-if="user.type === 'student'"
              :to="'/' + user._id + '/studentportal/resources'"
            >
              Resources
              <div class="divider" />
            </b-nav-item>
            <b-nav-item
              v-if="user.type === 'student'"
              :to="'/' + user._id + '/studentportal/reports'"
            >
              Reports
              <div class="divider" />
            </b-nav-item>
            <b-nav-item
              v-if="user.type !== 'student' && switchToParentPortal"
              :to="'/' + user._id + '/parentportal/reports'"
            >
              Reports
              <div class="divider" />
            </b-nav-item>
            <b-nav-item
              v-if="user.type === 'student'"
              :to="'/' + user._id + '/studentportal/asktutor'"
            >
              Ask A Tutor
              <div class="divider" />
            </b-nav-item>
            <b-nav-item
              v-if="user.type === 'student'"
              :to="'/' + user._id + '/studentportal/help'"
            >
              Help
              <div class="divider" />
            </b-nav-item>

            <b-nav-item class="grp1 hidden-nav" :to="getProfileLink">
              Profile
              <div class="divider" />
            </b-nav-item>
            <b-nav-item
              v-if="user.type !== 'student'"
              :to="'/' + user._id + '/studentportal'"
              class="grp1 hidden-nav"
            >
              View as Student
              <div class="divider" />
            </b-nav-item>

            <!-- portal menu items -->
            <template
              v-if="
                user &&
                  (user.type === 'admin' ||
                    user.type === 'consultant' ||
                    user.type === 'tutor')
              "
            >
              <b-nav-item
                class="grp-1"
                href="#"
                @click.prevent="switchPortals"
              >
                <b-button size="sm" class="btn-primary switch-btn">
                  {{
                    switchToParentPortal
                      ? "Switch to admin"
                      : "Switch to learning system"
                  }}
                </b-button>
              </b-nav-item>
            </template>
            <!-- <div class="side-bdr" /> -->

            <b-nav-item class="grp1 hidden-nav" @click="logoutUser">
              Logout
              <div class="divider" />
            </b-nav-item>

            <!-- Dropdown is shown only for larger devices -->
            <b-nav-item-dropdown class="nav-profile" right>
              <!-- Using 'button-content' slot -->
              <template v-if="user" v-slot:button-content>
                <b-img
                  class="prfile-nav-image"
                  style="height:46px; width:46px"
                  fluid
                  :src="imgSrc"
                />
                <span class="user-name ml-1 mr-1">My Account</span>
              </template>
              <b-dropdown-item href="#" :to="getProfileLink">
                <span>
                  <Profile width="25" height="25" />
                  <div class="drp-wrap">Profile</div>
                </span>
              </b-dropdown-item>
              <hr>
              <b-dropdown-item
                v-if="user.type !== 'student'"
                :to="'/' + user._id + '/studentportal'"
              >
                <span>
                  <Vstudent width="18" height="22" />
                  <div class="drp-wrap">View as Student</div>
                </span>
              </b-dropdown-item>
              <hr>
              <b-dropdown-item v-if="user" href="#" @click="logoutUser">
                <span>
                  <Logout width="18" height="22" />
                  <div class="drp-wrap">Logout</div>
                </span>
              </b-dropdown-item>
            </b-nav-item-dropdown>
            <!-- </div> -->
          </b-navbar-nav>
        </b-collapse>
      </b-navbar>
      <!-- <b-navbar toggleable="lg" style="background-color:#EEE;padding:0" v-if="user">
      <b-collapse id="nav-collapse" is-nav> -->
      <!-- <b-nav-text
          class="text-sm-right text-center ml-lg-auto ml-md-2 ml-0 hot-line"
          style="padding-right:10px"
          v-if="user.type==='student'"
        >Teacher Hotline : 00012312312 | Open hours : 6:00am - 10:00pm</b-nav-text>-->
      <!-- </b-collapse>
    </b-navbar> -->
      <b-container fluid class="text-center" style="width:95%">
        <br>
        <nuxt />
      </b-container>

      <no-ssr>
        <!-- Else we get Error -  The client-side rendered virtual DOM tree is not matching server-rendered content -->
        <notifications position="bottom right" />
      </no-ssr>
    </div>
    <b-container fluid>
      <b-row>
        <b-col lg="4" md="12" />
        <b-col lg="12" md="12">
          <footer class="footer text-center text-md-center">
            Teacher Helpline: 0800 9995112 {{ getOpenHoursText }}
          </footer>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>
<script>
import { mapActions, mapState, mapMutations, mapGetters } from 'vuex'
import NoSSR from 'vue-no-ssr'
import moment from 'moment-timezone'
import { getProfilePic } from '@/common'
import Profile from '@/components/common/SVG/Profile'
import Vstudent from '@/components/common/SVG/Vstudent'
import Logout from '@/components/common/SVG/Logout'

export default {
  components: {
    Profile,
    Vstudent,
    Logout,
    'no-ssr': NoSSR
  },
  data () {
    return {
      // switchPortalString: 'Switch to learning System'
      openTime: ''
    }
  },
  computed: {
    ...mapState('auth', { actualUser: 'user' }),
    ...mapState('users', ['switchToParentPortal']),
    ...mapGetters('misc', { user: 'getCurrentUserData' }),
    impersonating () {
      // DEPRECATED
      return (
        this.user && this.actualUser && this.user._id !== this.actualUser._id
      )
    },
    getProfileLink () {
      return this.user.type !== 'student'
        ? `/${this.user._id}/parentportal/profile`
        : `/${this.user._id}/studentportal/profile`
    },
    imgSrc () {
      return getProfilePic(this.user.profilePicture)
    },
    homeLink () {
      let homeurl = '/' + this.user._id + '/' + this.user.type + 'portal'
      if (this.user.type === 'admin' && this.switchToParentPortal) {
        homeurl = '/' + this.user._id + '/parentportal'
      }
      return homeurl
    },
    inStudentPortal () {
      if (this.$route) {
        return this.$router.history.current.path
          .split('/')
          .pop()
          .includes('student')
      } else {
        return false
      }
    },
    inStudentPortalHome () {
      if (this.$route) {
        return this.$router.history.current.path === `/${this.user._id}/studentportal`
      } else {
        return false
      }
    },
    getOpenHoursText () {
      const now = moment.tz('UTC')
      let nextDay = moment(now).add(1, 'days')
      let startTime
      let endTime
      let nextDayStart = moment(now).set({ h: 16, m: 0, s: 0 })

      if (now.day() === 6) {
        startTime = moment(now).set({ h: 10, m: 0, s: 0 })
        endTime = moment(now).set({ h: 13, m: 0, s: 0 })
        nextDay = moment(now).add(2, 'days')
      } else if (now.day() === 5) {
        startTime = moment(now).set({ h: 16, m: 0, s: 0 })
        endTime = moment(now).set({ h: 19, m: 0, s: 0 })
        // saturdays times
        nextDayStart = moment(now).set({ h: 10, m: 0, s: 0 })
      } else {
        startTime = moment(now).set({ h: 16, m: 0, s: 0 })
        endTime = moment(now).set({ h: 19, m: 0, s: 0 })
      }

      const timeMinutes = startTime.diff(now, 'minutes')
      if (timeMinutes > 0 && now.day() !== 0) {
        // let timeMinutes = Math.round(timeMinutes);
        let beforeTimeHours
        if (timeMinutes / 60 < 1) {
          beforeTimeHours = 0
        } else {
          beforeTimeHours = Math.floor(timeMinutes / 60)
        }
        const beforeTimeMinutes = timeMinutes % 60
        if (timeMinutes < 60) {
          return `Opens in ${beforeTimeMinutes} minutes at ${startTime.format(
            'ha'
          )} `
        }
        return `Opens in ${beforeTimeHours}h and ${beforeTimeMinutes}m at ${startTime.format(
          'ha'
        )} `
      } else {
        const afterTime = moment.duration(endTime.diff(now)).asMinutes()
        if (afterTime > 0 && now.day() !== 0) {
          return 'Open now '
        } else {
          return `Opens on ${nextDay.format('dddd')} at ${nextDayStart.format(
            'ha'
          )} `
        }
      }
    }
  },
  mounted () {
    if (!this.$ability.rules.length && this.$store.state.auth.payload) {
      /// set ability plugin details if none are set and user logged in
      this.$ability.update(this.$store.state.auth.payload.rules)
    }
  },
  methods: {
    stopImpersonate () {
      this.setCurrentUserId(this.actualUser._id)
      this.$router.push('/')
    },
    ...mapMutations('users', ['setSwitchToParentPortal']),
    ...mapMutations('misc', ['setCurrentUserId', 'resetImpersonationData']),
    ...mapActions('misc', ['logEvent']),
    ...mapActions('students', {
      patchStudents: 'patch'
    }),
    switchPortals () {
      this.setSwitchToParentPortal()
      // this.switchPortalString = (this.switchToParentPortal) ? 'Switch to admin' : 'Switch to learning System'
      this.$router.push('/')
    },
    async logoutUser () {
      const loggedoutUser = { ...this.user }
      if (loggedoutUser.type === 'student') {
        loggedoutUser.coursePermissions.forEach((coursePermission) => {
          coursePermission.allowModifyOperationMode = false
        })
        await this.patchStudents([
          loggedoutUser._id,
          { coursePermissions: loggedoutUser.coursePermissions }
        ])
      }
      this.resetImpersonationData()
      await this.logout()

      this.$router.go('/login')
    },

    getLastAccessedDate () {
      const lastAccessedDate = this.student.lastAccessedDate
        ? this.$moment(new Date(this.student.lastAccessedDate)).format(
          'h:mm:ss a on DD MMMM YYYY'
        )
        : 'Not accessed yet'
      return lastAccessedDate
    },

    ...mapActions('auth', ['logout']),
    checkRouteActive (checkingRoute) {
      return this.pathURL === checkingRoute
    }
  }
}
</script>

<style scoped>
#app {
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}
</style>
