import * as _ from 'lodash'
const defaultSelectedParent = {
  _id: null,
  firstName: '',
  lastName: '',
  address: '',
  username: '',
  password: '',
  active: true,
  email: '',
  type: '',
  office: null,
  subscription: null,
  subscriberParent: false

}
export default {
  state: {
    switchToParentPortal: false,
    selectedParent: defaultSelectedParent,
    detailedUserList: [],
    parentsInFamily: []
  },
  getters: {
    getSwitchToParentPortal (state) {
      return state.switchToParentPortal
    },
    getSelectedParent (state) {
      return _.cloneDeep(state.selectedParent)
    },
    getDetailedUserList (state) {
      return state.detailedUserList
    },
    getParentsInFamily (state) {
      return state.parentsInFamily
    }
  },
  mutations: {
    setSwitchToParentPortal (state) {
      state.switchToParentPortal = !state.switchToParentPortal
    },
    setSelectedParentId (state, value) {
      state.selectedParent._id = value
    },
    setSelectedParentFirstName (state, value) {
      state.selectedParent.firstName = value
    },
    setSelectedParentLastName (state, value) {
      state.selectedParent.lastName = value
    },
    setSelectedParentAdd (state, value) {
      state.selectedParent.address = value
    },
    setSelectedParentUsername (state, value) {
      state.selectedParent.username = value
    },
    setSelectedParentPassword (state, value) {
      state.selectedParent.password = value
    },
    setSelectedParentActive (state, value) {
      state.selectedParent.active = value
    },
    setSelectedParentEmail (state, value) {
      state.selectedParent.email = value
    },
    setSelectedParentType (state, value) {
      state.selectedParent.type = value
    },
    setSelectedParentOffice (state, value) {
      state.selectedParent.office = value
    },
    setSelectedParentSubscription (state, value) {
      state.selectedParent.subscription = value
    },
    setSelectedParentSubscriberParent (state, value) {
      state.selectedParent.subscriberParent = value
    },
    setSelectedParent (state, newParentValue) {
      state.selectedParent = { ...newParentValue }
    },
    setSelectedParentDefault (state) {
      state.selectedParent = Object.assign(
        state.selectedParent,
        defaultSelectedParent
      )
    },
    setDetailedUserList (users) {
      this.detailedUserList = users
    },
    setParentsInFamily (state, newParents) {
      state.parentsInFamily = newParents
    }
  }
}
