import feathers from '@feathersjs/feathers'
import socketio from '@feathersjs/socketio-client'
import auth from '@feathersjs/authentication-client'
import { stripSlashes } from '@feathersjs/commons'
import { Timeout } from '@feathersjs/errors'
import io from 'socket.io-client'
import feathersVuex from 'feathers-vuex'
import { ServiceTypes } from 'src/declarations'
import * as Cookies from 'js-cookie'

const socket: SocketIOClient.Socket = (() => {
  if (process.env.BACKEND_PORT && process.client) {
    return io(`//${window.location.hostname}:${process.env.BACKEND_PORT}`)
  }
  if (process.env.BACKEND_URL) {
    return io(process.env.BACKEND_URL)
  }
  return io()
})()

// as per https://vuex.feathersjs.com/api-overview.html#feathers-client-feathers-vuex
const feathersClient = feathers<ServiceTypes>()
  .configure(socketio(socket, {
    timeout: 10000
  })).configure((feathers) => {
    // hook to implement a timeout on getting authentiation
    // has to be before authclient setup
    feathers.hooks({
      before: {
        all: [(context) => {
          const { app, path, method, service, app: { authentication: authService } } = context
          // bypass this for the actual auth service
          if (stripSlashes(authService.options.path) === path && method === 'create') {
            return context
          }
          return new Promise((resolve, reject) => {
            // create future timer for timeout MS in future
            const timeoutId = setTimeout(() => reject(
              new Timeout(`Timeout of ${service.timeout}ms exceeded getting auth to call ${method} on ${path}`, {
                timeout: service.timeout,
                method,
                path
              })
            ), service.timeout)
            Promise.resolve(app.get('authentication')).then(() => {
              clearTimeout(timeoutId)
              resolve(context)
            }).catch(reject)
          })
        }]
      }
    })
  })
  // .configure(restClient.fetch(window.fetch, {
  //   credentials: 'omit'
  // } as RequestInit))
  .configure(auth({
    storage: {
      getItem: key => Cookies.get(key),
      setItem: (key, value) => Cookies.set(key, value, { expires: 7 }),
      removeItem: key => Cookies.remove(key)
    }

  }))
  .hooks({
    before: {
      all: [(context) => {
        if (['create', 'update', 'patch'].includes(context.method)) {
          delete context.data.__id
          delete context.data.__isTemp
        }
        return context
      }]
    }
  })

// Setting up feathers-vuex
const { makeServicePlugin, makeAuthPlugin, BaseModel, models, FeathersVuex } = feathersVuex(
  feathersClient,
  {
    serverAlias: 'api', // optional for working with multiple APIs (this is the default value)
    idField: '_id', // Must match the id field in your database table/collection
    whitelist: ['$regex', '$options']
  }
)

export default feathersClient
export { makeAuthPlugin, makeServicePlugin, BaseModel, models, FeathersVuex }
