
import { Module } from 'vuex'
import { FeathersError } from '@feathersjs/errors'
import { Id, Params, Paginated, Service } from '@feathersjs/feathers'
import { FeathersVuexOptions } from 'feathers-vuex/dist/service-module/types'
import { AuthState } from 'feathers-vuex/dist/auth-module/types'
import { AuthenticationResult } from '@feathersjs/authentication/lib'
import { RawRule } from '@casl/ability'
import feathersClient, { makeServicePlugin, makeAuthPlugin, BaseModel } from '../feathers/index'
import customStudentState from '../store-extensions/students'
import customParentState from '../store-extensions/parents'
import customUserState from '../store-extensions/users'
import customSubscription from '../store-extensions/subscriptions'
import customLesson from '../store-extensions/lessons'
import customOffices from '../store-extensions/offices'
import customCourse from '../store-extensions/courses'
import customStudyPlanState from '../store-extensions/study-plans'
import customResults from '../store-extensions/results'
import { User as UserMongooseModel } from '../../src/models/users.model'
import { Program as ProgramMongooseModel } from '../../src/models/programs.model'
import { CourseSet as CourseSetMongooseModel } from '../../src/models/course-sets.model'
import { Course as CourseMongooseModel } from '../../src/models/courses.model'
import { Subscription as SubscriptionMongooseModel } from '../../src/models/subscriptions.model'
import { FeathersVuexModel } from '@/feathers/feathers-vuex-types'

export interface FeathersVuexAuthState<T, P extends AuthenticationResult = AuthenticationResult> extends Omit<AuthState, 'payload' | 'user' | 'errorOnLogout' | 'errorOnAuthenticate'> {
  user: T;
  payload: P;
  errorOnAuthenticate: FeathersError | null;
  errorOnLogout: FeathersError | null;
  userService: string;
  serverAlias: string;
  responseEntityField: string;
}

export interface FeathersVuexServiceModuleState<T> extends Required<FeathersVuexOptions> {
  ids: Id[];
  keyedById: Record<Id, T>;
  copiesById: Record<Id, T>;
  tempsById: Record<Id, T>;
  tempsByNewId: Record<Id, T>;
  pagination: {
    defaultLimit: number | null;
    defaultSkip: number | null;
  };

  isFindPending: boolean;
  isGetPending: boolean;
  isCreatePending: boolean;
  isUpdatePending: boolean;
  isPatchPending: boolean;
  isRemovePending: boolean;

  errorOnFind: null | FeathersError;
  errorOnGet: null | FeathersError;
  errorOnCreate: null | FeathersError;
  errorOnUpdate: null | FeathersError;
  errorOnPatch: null | FeathersError;
  errorOnRemove: null | FeathersError;
  modelName: string | null;
}

export interface V20State {
  auth: FeathersVuexAuthState<UserMongooseModel, AuthenticationResult & {
    rules: RawRule[];
    subscription: SubscriptionMongooseModel;
    user: UserMongooseModel;
  }> & { publicPages: string[] };
  misc: FeathersVuexServiceModuleState<any>; // TODO fix the generic type for each of these
  subscriptions: FeathersVuexServiceModuleState<SubscriptionMongooseModel>;
  students: FeathersVuexServiceModuleState<any>;
  parents: FeathersVuexServiceModuleState<any>;
  users: FeathersVuexServiceModuleState<UserMongooseModel>;
  admins: FeathersVuexServiceModuleState<any>;
  consultants: FeathersVuexServiceModuleState<any>;
  lessons: FeathersVuexServiceModuleState<any>;
  courses: FeathersVuexServiceModuleState<any>;
  'course-sets': FeathersVuexServiceModuleState<any>;
  programs: FeathersVuexServiceModuleState<any>;
  offices: FeathersVuexServiceModuleState<any>;
  email: FeathersVuexServiceModuleState<any>;
  'event-log': FeathersVuexServiceModuleState<any>;
  'account-recovery': FeathersVuexServiceModuleState<any>;
  counters: FeathersVuexServiceModuleState<any>;
  'study-plans': FeathersVuexServiceModuleState<any>;
  results: FeathersVuexServiceModuleState<any>;
  diplomas : FeathersVuexServiceModuleState<any>;
}

export type V20RootGetters = Readonly<{
  'programs/find': (params?: Params) => Paginated<Required<ProgramMongooseModel>>
  'course-sets/find': (params?: Params) => Paginated<Required<CourseSetMongooseModel>>
  'courses/find': (params?: Params) => Paginated<Required<CourseMongooseModel>>
  'courses/get': (id: Id, params?: Params) => Required<CourseMongooseModel> | undefined
}>

declare module '@/plugins/plugins.client' {
  interface FeathersVuexModels {
    Program: FeathersVuexModel<ProgramMongooseModel>
    CourseSet: FeathersVuexModel<CourseSetMongooseModel>
    Course: FeathersVuexModel<CourseMongooseModel>
  }
}

class Subscription extends BaseModel {
  static modelName = 'Subscription'
}

class Student extends BaseModel {
  static modelName = 'Student'
}

class Parent extends BaseModel {
  static modelName = 'Parent'
}

class User extends BaseModel {
  static modelName = 'User'
}

class Admin extends BaseModel {
  static modelName = 'Admin'
}

class Consultant extends BaseModel {
  static modelName = 'Consultant'
}

class Lesson extends BaseModel {
  static modelName = 'Lesson'
}

class Course extends BaseModel {
  static modelName = 'Course'
}

class CourseSet extends BaseModel {
  static modelName = 'CourseSet'
}

class Program extends BaseModel {
  static modelName = 'Program'
}

class Office extends BaseModel {
  static modelName = 'Office'
}

class Email extends BaseModel {
  static modelName = 'Email'
}

class EventLog extends BaseModel {
  static modelName = 'EventLog'
}

class AccountRecovery extends BaseModel {
  static modelName = 'AccountRecovery'
}

class Counter extends BaseModel {
  static modelName = 'Counter'
}

class StudyPlan extends BaseModel {
  static modelName = 'StudyPlan'
}

class Result extends BaseModel {
  static modelName = 'Result'
}

class Diploma extends BaseModel {
  static modelName = 'Diploma'
}

export default {
  plugins: [
    makeServicePlugin({
      service: feathersClient.service('subscriptions') as unknown as Service<any>,
      Model: Subscription,
      ...customSubscription,
      enableEvents: process.client
    }),
    makeServicePlugin({
      service: feathersClient.service('students') as unknown as Service<any>,
      Model: Student,
      ...customStudentState,
      enableEvents: process.client
    }),
    makeServicePlugin({
      service: feathersClient.service('parents') as unknown as Service<any>,
      Model: Parent,
      ...customParentState,
      enableEvents: process.client
    }),
    makeServicePlugin({
      service: feathersClient.service('users') as unknown as Service<any>,
      Model: User,
      ...customUserState,
      enableEvents: process.client
    }),
    makeServicePlugin({
      service: feathersClient.service('admins'),
      Model: Admin,
      enableEvents: process.client
    }),
    makeServicePlugin({
      service: feathersClient.service('consultants'),
      Model: Consultant,
      enableEvents: process.client
    }),
    makeServicePlugin({
      service: feathersClient.service('lessons'),
      Model: Lesson,
      ...customLesson,
      enableEvents: process.client
    }),
    makeServicePlugin({
      service: feathersClient.service('courses'),
      Model: Course,
      ...customCourse,
      enableEvents: process.client
    }),
    makeServicePlugin({
      service: feathersClient.service('course-sets'),
      Model: CourseSet,
      enableEvents: process.client
    }),
    makeServicePlugin({
      service: feathersClient.service('programs'),
      Model: Program,
      enableEvents: process.client
    }),
    makeServicePlugin({
      service: feathersClient.service('offices'),
      Model: Office,
      ...customOffices,
      enableEvents: process.client
    }),
    makeServicePlugin({
      service: feathersClient.service('email'),
      Model: Email,
      enableEvents: process.client
    }),
    makeServicePlugin({
      service: feathersClient.service('event-log'),
      Model: EventLog,
      enableEvents: process.client
    }),
    makeServicePlugin({
      service: feathersClient.service('account-recovery'),
      Model: AccountRecovery,
      enableEvents: process.client
    }),
    makeServicePlugin({
      service: feathersClient.service('counters'),
      Model: Counter,
      enableEvents: process.client
    }),
    makeServicePlugin({
      service: feathersClient.service('study-plans'),
      Model: StudyPlan,
      ...customStudyPlanState,
      enableEvents: process.client
    }),
    makeServicePlugin({
      service: feathersClient.service('results'),
      Model: Result,
      ...customResults,
      enableEvents: process.client
    }),
    makeServicePlugin({
      service: feathersClient.service('diplomas'),
      Model: Diploma,
      enableEvents: process.client
    }),

    // Setup the auth plugin.
    makeAuthPlugin({
      userService: 'users',
      state: {
        publicPages: ['login', 'index']
      }
    })
  ]
} as Module<V20State, V20State>
