export default {
  state: {
    selectedParent: {
      _id: null,
      firstName: '',
      lastName: '',
      address: '',
      username: '',
      password: '',
      active: true,
      office: null,
      subscription: null
    }
  },
  getters: {
    getSelectedParent (state) {
      return state.selectedParent
    }
  },
  mutations: {
    setSelectedParent (state, newParentValue) {
      state.selectedParent = Object.assign(state.selectedParent, newParentValue)
    },
    setSelectedParentDefault (state) {
      const defaultSelectedParent = {
        _id: null,
        firstName: '',
        lastName: '',
        address: '',
        username: '',
        password: '',
        active: true,
        office: null,
        subscription: null
      }
      state.selectedParent = Object.assign(
        state.selectedParent,
        defaultSelectedParent
      )
    }
  }
}
