import Vue from 'vue'
import { library } from '@fortawesome/fontawesome-svg-core'
import {
  faMars,
  faVenus,
  faBan,
  faCheckCircle,
  faDownload,
  faEdit,
  faAward,
  faUserEdit,
  faBriefcase,
  faSearch,
  faPrint,
  faUserAlt,
  faUserAltSlash,
  faUsersCog,
  faAngleLeft,
  faAngleRight,
  faEye,
  faEyeSlash,
  faExpand,
  faCompress
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

library.add(
  faMars,
  faVenus,
  faBan,
  faCheckCircle,
  faDownload,
  faEdit,
  faAward,
  faUserEdit,
  faBriefcase,
  faSearch,
  faPrint,
  faUserAlt,
  faUserAltSlash,
  faUsersCog,
  faAngleLeft,
  faAngleRight,
  faEye,
  faEyeSlash,
  faExpand,
  faCompress
)
Vue.component('font-awesome-icon', FontAwesomeIcon)
