import * as _ from 'lodash'
export default {
  state: {
    selectedLesson: {
      fslessonapi: {}
    },
    nextLesson: {
      fslessonapi: {}
    },
    selectedLessonsList: [],
    videoBaseURL: ''
  },
  getters: {
    getSelectedLesson (state) {
      return _.cloneDeep(state.selectedLesson)
    },
    getNextLesson (state) {
      return _.cloneDeep(state.nextLesson)
    },
    getSelectedLessonsList (state) {
      return _.cloneDeep(state.selectedLessonsList)
    },
    getVideoBaseURL (state) {
      return state.videoBaseURL
    }
  },
  mutations: {
    setSelectedLesson (state, newValue) {
      state.selectedLesson = newValue
    },
    setNextLesson (state, newValue) {
      state.nextLesson = newValue
    },
    setSelectedLessonsList (state, newValue) {
      state.selectedLessonsList = newValue
    },
    setVideoBaseURL (state, newValue) {
      state.videoBaseURL = newValue
    }
  }
}
