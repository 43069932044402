export default {
  state: {
    selectedStudent: {
      _id: null,
      firstName: '',
      lastName: '',
      password: '',
      DOB: null,
      username: ''
    },
    studentsInFamily: []
  },
  getters: {
    getSelectedStudent (state) {
      return state.selectedStudent
    },
    getStudentsInFamily (state) {
      return state.studentsInFamily
    }
  },
  mutations: {
    setSelectedStudent (state, newStudentValue) {
      state.selectedStudent = newStudentValue
    },
    setSelectedStudentDefault (state) {
      // reset student state
      state.selectedStudent = {
        _id: null,
        firstName: '',
        lastName: '',
        password: '',
        DOB: null,
        username: ''
      }
    },
    setSelectedStudentDOB (state, dob) {
      state.selectedStudent.DOB = dob
    },
    setStudentsInFamily (state, newStudents) {
      state.studentsInFamily = newStudents
    }
  }
}
