import * as Cookies from 'js-cookie'
// Plugin to Authticate before Nuxt Render.
// This is solution for FeathersBug which failed to read the cookie if user refresh nuxt app
export default async function (context) {
  const { store, route, app } = context
  if (route.path === '/login' || !store.state.auth.accessToken) {
    return
  }
  try {
    await store.dispatch('auth/authenticate')
  } catch (ex) {
    Cookies.remove('veux')
    localStorage.removeItem('vuex')
    location.reload()
    return app.$router('/login')
  }
}
