import Vue from 'vue'
import Router from 'vue-router'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _56c8f4ff = () => interopDefault(import('../../client/pages/login.vue' /* webpackChunkName: "pages/login" */))
const _e5bd5c30 = () => interopDefault(import('../../client/pages/index.vue' /* webpackChunkName: "pages/index" */))
const _6ec8a9be = () => interopDefault(import('../../client/pages/_userId/index.vue' /* webpackChunkName: "pages/_userId/index" */))
const _62431b2a = () => interopDefault(import('../../client/pages/_userId/AdminPortal/index.vue' /* webpackChunkName: "pages/_userId/AdminPortal/index" */))
const _fec382c6 = () => interopDefault(import('../../client/pages/_userId/ParentPortal/index.vue' /* webpackChunkName: "pages/_userId/ParentPortal/index" */))
const _645ec516 = () => interopDefault(import('../../client/pages/_userId/StudentPortal/index.vue' /* webpackChunkName: "pages/_userId/StudentPortal/index" */))
const _6ec92b58 = () => interopDefault(import('../../client/pages/_userId/AdminPortal/Subscription/index.vue' /* webpackChunkName: "pages/_userId/AdminPortal/Subscription/index" */))
const _6636de68 = () => interopDefault(import('../../client/pages/_userId/ParentPortal/ContactUs/index.vue' /* webpackChunkName: "pages/_userId/ParentPortal/ContactUs/index" */))
const _5fee0ca9 = () => interopDefault(import('../../client/pages/_userId/ParentPortal/Lessons/index.vue' /* webpackChunkName: "pages/_userId/ParentPortal/Lessons/index" */))
const _6c55aa97 = () => interopDefault(import('../../client/pages/_userId/ParentPortal/Profile/index.vue' /* webpackChunkName: "pages/_userId/ParentPortal/Profile/index" */))
const _aeda61a6 = () => interopDefault(import('../../client/pages/_userId/ParentPortal/Reports/index.vue' /* webpackChunkName: "pages/_userId/ParentPortal/Reports/index" */))
const _1dafaa0c = () => interopDefault(import('../../client/pages/_userId/StudentPortal/AskTutor/index.vue' /* webpackChunkName: "pages/_userId/StudentPortal/AskTutor/index" */))
const _3dff63e0 = () => interopDefault(import('../../client/pages/_userId/StudentPortal/Help/index.vue' /* webpackChunkName: "pages/_userId/StudentPortal/Help/index" */))
const _7688a810 = () => interopDefault(import('../../client/pages/_userId/StudentPortal/Profile/index.vue' /* webpackChunkName: "pages/_userId/StudentPortal/Profile/index" */))
const _9a7466b4 = () => interopDefault(import('../../client/pages/_userId/StudentPortal/Reports/index.vue' /* webpackChunkName: "pages/_userId/StudentPortal/Reports/index" */))
const _f2ce02e8 = () => interopDefault(import('../../client/pages/_userId/StudentPortal/Resources/index.vue' /* webpackChunkName: "pages/_userId/StudentPortal/Resources/index" */))

// TODO: remove in Nuxt 3
const emptyFn = () => {}
const originalPush = Router.prototype.push
Router.prototype.push = function push (location, onComplete = emptyFn, onAbort) {
  return originalPush.call(this, location, onComplete, onAbort)
}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: decodeURI('/'),
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/login",
    component: _56c8f4ff,
    name: "login"
  }, {
    path: "/",
    component: _e5bd5c30,
    name: "index"
  }, {
    path: "/:userId",
    component: _6ec8a9be,
    name: "userId"
  }, {
    path: "/:userId/AdminPortal",
    component: _62431b2a,
    name: "userId-AdminPortal"
  }, {
    path: "/:userId/ParentPortal",
    component: _fec382c6,
    name: "userId-ParentPortal"
  }, {
    path: "/:userId/StudentPortal",
    component: _645ec516,
    name: "userId-StudentPortal"
  }, {
    path: "/:userId/AdminPortal/Subscription",
    component: _6ec92b58,
    name: "userId-AdminPortal-Subscription"
  }, {
    path: "/:userId/ParentPortal/ContactUs",
    component: _6636de68,
    name: "userId-ParentPortal-ContactUs"
  }, {
    path: "/:userId/ParentPortal/Lessons",
    component: _5fee0ca9,
    name: "userId-ParentPortal-Lessons"
  }, {
    path: "/:userId/ParentPortal/Profile",
    component: _6c55aa97,
    name: "userId-ParentPortal-Profile"
  }, {
    path: "/:userId/ParentPortal/Reports",
    component: _aeda61a6,
    name: "userId-ParentPortal-Reports"
  }, {
    path: "/:userId/StudentPortal/AskTutor",
    component: _1dafaa0c,
    name: "userId-StudentPortal-AskTutor"
  }, {
    path: "/:userId/StudentPortal/Help",
    component: _3dff63e0,
    name: "userId-StudentPortal-Help"
  }, {
    path: "/:userId/StudentPortal/Profile",
    component: _7688a810,
    name: "userId-StudentPortal-Profile"
  }, {
    path: "/:userId/StudentPortal/Reports",
    component: _9a7466b4,
    name: "userId-StudentPortal-Reports"
  }, {
    path: "/:userId/StudentPortal/Resources",
    component: _f2ce02e8,
    name: "userId-StudentPortal-Resources"
  }],

  fallback: false
}

export function createRouter () {
  return new Router(routerOptions)
}
