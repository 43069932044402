
import Vue from 'vue'
import Notifications from 'vue-notification'
import moment from 'vue-moment'
import VueGoogleCharts from 'vue-google-charts'
import VueI18n from 'vue-i18n'
import { FeathersVuex } from 'feathers-vuex/dist'
import CompositionApi from '@vue/composition-api'
import { Plugin } from '@nuxt/types'

import { abilitiesPlugin } from '@casl/vue'
import 'video.js/dist/video-js.css'
import 'vue-loading-overlay/dist/vue-loading.css'
Vue.use(moment)
Vue.use(Notifications)
Vue.use(VueGoogleCharts)
Vue.use(abilitiesPlugin)

export interface FeathersVuexModels {
}

type FeathersVuexType = {
  api: FeathersVuexModels
}

declare module 'vue/types/vue' {
  interface VueConstructor {
    $FeathersVuex: FeathersVuexType;
  }
  interface Vue {
    $FeathersVuex: FeathersVuexType;
  }
}

Vue.use(FeathersVuex)

Vue.use(VueI18n)
Vue.use(CompositionApi)
// global error hander for vue
Vue.config.errorHandler = (err, vm, info) => {
  if (process.env.NODE_ENV !== 'production') {
    // eslint-disable-next-line no-console
    console.error('Vue errorHandler -> err, vm, info', err, vm, info)
  }
  if (vm && vm.$nuxt) {
    vm.$nuxt.error(err)
  }
}

declare module 'vuex' {
  interface Store<S> {
    $FeathersVuex: FeathersVuexType;
  }
}

// attach FeathersVuex to the store.
const pluginsInject: Plugin = ({ store }) => {
  store.$FeathersVuex = Vue.$FeathersVuex
}

export default pluginsInject
