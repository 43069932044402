import { Plugin } from '@nuxt/types'
import feathers from '../feathers'

declare module 'vue/types/vue' {
  interface Vue {
    $feathers: typeof feathers;
  }
  interface VueConstructor {
    $feathers: typeof feathers;
  }

}

declare module '@nuxt/types' {
  interface NuxtAppOptions {
    $feathers: typeof feathers;
  }
}

declare module 'vuex/types/index' {
  interface Store<S> {
    $feathers: typeof feathers;
  }
}

const feathersInject: Plugin = (_context, inject) => {
  inject('feathers', feathers)
}

export default feathersInject
