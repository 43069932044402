export default {
  state: {
    selectedResult: {
      result: {},
      questionResults: {}
    }
  },
  getters: {
    getSelectedResult (state) {
      return state.selectedResult
    }
  },
  mutations: {
    setSelectedResult (state, newResultsValue) {
      state.selectedResult = Object.assign(
        state.selectedResult,
        newResultsValue
      )
    }
  }
}
