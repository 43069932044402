export default {
  state: {
    selectedOffice: {
      _id: null,
      name: '',
      telephone: '',
      email: ''
    }
  },
  getters: {
    getSelectedOffice (state) {
      return state.selectedOffice
    }
  },
  mutations: {
    setSelectedOffice (state, newlySelectedOffice) {
      Object.assign(
        state.selectedOffice,
        newlySelectedOffice
      )
    },
    setOfficeName (state, value) {
      state.selectedOffice.name = value
    },
    setOfficeTelephone (state, value) {
      state.selectedOffice.telephone = value
    },
    setOfficeEmail (state, value) {
      state.selectedOffice.email = value
    },
    resetSelectedOffice (state) {
      const obj = {
        _id: null,
        name: '',
        telephone: '',
        email: ''
      }
      Object.assign(state.selectedOffice, obj)
    }
  }
}
