import _ from 'lodash'
import imgSrc from '@/assets/img/default-user-img.png'

export const makeid = (length) => {
  let result = ''
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789'
  const charactersLength = characters.length
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength))
  }
  return result
}
export const getFastestUrl = (BASE_URLS) => {
  return new Promise((resolve, reject) => {
    // for (let index = 1; index <= 3; index++) {
    //   BASE_URLS.push(url)
    // }
    // return resolve('something')
    Promise.race(
      BASE_URLS.map((url) => {
        return fetch(`${url}/speedtest.txt`, {
          mode: 'cors',
          cache: 'no-cache'
        }).then(() => url)
      })
    )
      .then((url) => {
        resolve(url)
      })
      .catch(() => {
        // console.log('Unable to find any suitable server!')
        reject(new Error('Error getting baseURL'))
      })
  })
}

export const searchLessonsByKeyword = (lessons, keyword) => {
  return lessons.filter((row) => {
    let found = false
    // check code
    if (row.lesson.code === keyword) {
      found = true
    }
    // check keywords
    if (
      !found &&
      _.find(row.lesson.keywords, (value) => {
        return value.includes(keyword)
      })
    ) {
      found = true
    }
    // check title and topic
    if (
      !found &&
      (row.lesson.title.toLowerCase().includes(keyword.toLowerCase()) || row.lesson.topic.toLowerCase().includes(keyword.toLowerCase()))
    ) {
      found = true
    }

    return found
  })
}
export const getBadgeColor = (score) => {
  const color = {
    color: '#cd7f32',
    text: 'Bronze'
  }

  if (score >= 90 && score < 100) {
    color.color = 'silver'
    color.text = 'Silver'
  } else if (score === 100) {
    color.color = 'gold'
    color.text = 'Gold'
  }
  // console.log('TCL: getBadgeColor -> color', color)
  return color
}

export const getSubscriptionWithLessons = async (subscriptionId, store) => { // this is an exact copy of the get/subscript hook (its copied here because the get/subscription hook sends unpopulated data)
  const subscriptionData = await store.dispatch('subscriptions/find', // using find because get sometimes doesnt populate the data (get maybe using cache )

    {
      query: {
        _id: subscriptionId,
        $populate: [
          {
            path: 'selectedCourses.courses',
            populate: {
              path: 'lessons.lesson'
            }
          },
          'selectedCourses.courseSet'
        ]
      }
    }
  )
  const subscription = _.cloneDeep(subscriptionData.data[0])
  if (!subscription.selectedCourses.length && subscription.program) {
    const program = await store.dispatch('programs/find', // using find because get sometimes doesnt populate the data (get maybe using cache )
      {
        query: {
          _id: subscription.program,
          $populate: [
            {
              path: 'courseSets',
              populate: {
                path: 'courses',
                populate: {
                  path: 'lessons.lesson'
                }
              }
            }
          ]
        }
      }
    )
    const programData = program.data[0].courseSets.map((r) => {
      const courses = r.courses
      const courseSet = r
      delete courseSet.courses
      return {
        courseSet,
        courses
      }
    })
    // console.log('TCL: getSubscriptionWithLessons -> programData', programData)
    subscription.selectedCourses = programData
  }
  const coursePermissions = []
  const activeCourses = []
  subscription.selectedCourses.forEach((selectedCourse) => {
    const activeCourse = {
      courseSetId: selectedCourse.courseSet._id,
      courseId: selectedCourse.courses[0]._id,
      mastery: 80
    }
    activeCourses.push(activeCourse)
    coursePermissions.push({
      courseSetId: selectedCourse.courseSet._id,
      allowModifyCourseDetails: false,
      allowModifyOperationMode: false,
      allowSelfNavigateVideo: false,
      defaultOperationMode: 'AUTO'
    })
  })
  subscription.defaultCoursePermissions = coursePermissions
  subscription.defaultActiveCourses = activeCourses
  return _.cloneDeep(subscription)
}

export const getAndFilterAllLessonsForCourse = (
  allCoursesInCourseSet,
  courseLessons,
  selectedFilter = null
) => {
  try {
    const filtered = []
    const all = _.flatten(allCoursesInCourseSet.map((r) => {
      // console.log('TCL: r', r)
      return r.lessons.map((row) => {
        // console.log('TCL: r', r)
        row.course = {
          _id: r._id,
          title: r.title
        }
        return row
      })
    })).filter(
      row => row
    ) // get lessons array, filter empty arrays finally flatten it to be all in one array

    let startedCurrent = false
    // since lessons are in order, every lesson before the current lessons are revision
    for (const lesson of all) {
      const foundInFiltered = _.find(filtered, function (row) {
        return row.lesson._id === lesson.lesson._id
      })
      const foundInCourseLessons = _.find(courseLessons, {
        lesson: lesson.lesson._id
      })
      const foundInCourseLessonsObj = courseLessons.find((row) => {
        return row.lesson._id === lesson.lesson._id
      })
      if (foundInCourseLessons || foundInCourseLessonsObj) {
        startedCurrent = true
      }
      if (!foundInFiltered) {
        if (foundInCourseLessons || foundInCourseLessonsObj) {
        /// checking if filter criteria is set and filtering accordingly
          if (
            selectedFilter === 'CURRENT' ||
          (selectedFilter !== 'REVISION' &&
            selectedFilter !== 'EXTENDED')
          ) {
            lesson.spType = 2 // CURRENT
          }
        } else if (!startedCurrent) {
          if (
            selectedFilter === 'REVISION' ||
          (selectedFilter !== 'CURRENT' &&
            selectedFilter !== 'EXTENDED')
          ) {
            lesson.spType = 1 // REVISION
          }
        } else if (startedCurrent) {
          if (
            selectedFilter === 'EXTENDED' ||
          (selectedFilter !== 'REVISION' &&
            selectedFilter !== 'CURRENT')
          ) {
            lesson.spType = 3 // EXTENDED
          }
        }
        if (lesson.spType) {
          filtered.push(lesson)
        }
      }
    }
    return filtered
  } catch (error) {
    throw new Error('Error in getAndFilterAllLessonsForCourse - ', error)
  }
}

export const getProfilePic = (profilePic) => {
  if (profilePic) {
    return Buffer.from(profilePic, 'base64').toString('ascii')
  } else {
    return imgSrc
  }
}

export const validationMessageData = {
  required: () => 'Required',
  minLength: ({ $params }) => `Must be at least ${$params.minLength.min} characters.`,
  maxLength: ({ $params }) => `Must be less than ${$params.maxLength.max} characters.`,
  minValue: ({ $params }) => `Must be after ${$params.minValue.min}.`,
  maxValue: ({ $params }) => `Must be below ${$params.maxValue.max}.`,
  checkDate: () => 'Date is required',
  sameAsPassword: () => 'Password must be identical',
  email: () => 'Invalid Email',
  numeric: () => 'Invalid Number',
  between: ({ $params }) => `Must be a value between ${$params.between.min} and ${$params.between.max}.`,
  ukphonenumber: () => 'Must be a valid UK phone number'

}

export const b64toBlob = (b64Data, contentType = '', sliceSize = 512) => {
  const byteCharacters = atob(b64Data)
  const byteArrays = []

  for (
    let offset = 0;
    offset < byteCharacters.length;
    offset += sliceSize
  ) {
    const slice = byteCharacters.slice(offset, offset + sliceSize)

    const byteNumbers = new Array(slice.length)
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i)
    }

    const byteArray = new Uint8Array(byteNumbers)
    byteArrays.push(byteArray)
  }

  const blob = new Blob(byteArrays, { type: contentType })
  return blob
}
