<template>
  <div class="container">
    <div v-if="error.code === 404 || error.statusCode === 404">
      <div class="background-message">
        404
      </div>
      <div class="wrap">
        <h1>
          We can't find what you were looking for!
        </h1>
        <!-- <b-button class="btn-back" variant="primary">
          Back to Home page
        </b-button> -->
      </div>
    </div>
    <div v-else>
      <div class="background-message">
        Oops
      </div>
      <div class="wrap">
        <h1>
          We ran into an error :(
        </h1>
        <!-- <b-button class="btn-back" variant="primary">
          Back to Home page
        </b-button> -->
      </div>
      <p>Code : {{ error.code }}</p>
    </div>
    <p v-if="error.message.length">
      Message : {{ error.message }}
    </p>
    <b-link to="/">
      <b-button class="btn-back" variant="primary">
        Back to Home page
      </b-button>
    </b-link>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  props: {
    error: {
      type: Object,
      default: undefined
    }
  },
  computed: {
    ...mapGetters('misc', { user: 'getCurrentUserData' })
  }
}
</script>
<style lang="scss" scoped>
.footer {
  display: none;
}
.container {
  display: block;
  margin: 30vh auto;
  font-family: 'Larsseit';
}
h1 {
  font-weight: 800;
  margin-bottom: 25px;
  font-size: 2.5vw;
}
.btn-back {
  border-radius: 30px;
}
.background-message {
  font-size: 15vw;
  opacity: 0.1;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: -1000;

}
.wrap {
  position: relative;
}
</style>
